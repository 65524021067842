'use client';
import { useRef } from "react";
import { ToastContainer, toast, ToastOptions } from "react-toastify";
import { auth } from "@/auth";
import { sendContactUsEmail } from 'app/server/actions/email';
import "react-toastify/dist/ReactToastify.css";
import styles from "./main.module.css";
import { Session } from "next-auth";

export default function MainView({ session }: { session: Session | null }) {
  const formRef = useRef<HTMLFormElement>(null);
  const handleAction = async (formData: FormData) => {    
    await sendContactUsEmail(formData);
    formRef.current?.reset();
    toast.success("Success!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
    });
  }

  return (
    <main className={styles.mainContainer}>
      <div className={styles.container}>
        <div
          className={styles.title}        >
          SPEED UP
          <br />
          YOUR
          <br />
          BIOENGINEERING
        </div>
        <div className={styles.formContainer}>
          <form ref={formRef} className={styles.form} action={handleAction} >
            <input
              required
              name="name"
              type="text"
              placeholder="Name"
            />
            <input
              required
              type="email"
              name="email"
              placeholder="Email"
            />
            <textarea
              name="summary"
              placeholder="We are happy to help you with any questions..."
            ></textarea>
            <div className={styles.submitDiv}>
              <button
                type="submit"
              >
                Contact us
              </button>
            </div>
            <ToastContainer closeButton={false} />
          </form>
        </div>
      </div>
      <div
        className={styles.contact}
      >
        contact us: <a href="mailto:info@nexio.bio">info@nexio.bio</a>
      </div>
      
    </main>
  );
}
